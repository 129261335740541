import type { SiteConfig } from "../sites.ts";

export const dataAssetConfig: SiteConfig = {
  company: {
    name: "DataAsset.Store",
    tagline: "Unlocking Data Potential",
    description: "Innovative data asset management and analytics solutions"
  },
  theme: {
    light: {
      primary: "#059669",    // Emerald 600
      secondary: "#7c3aed",  // Violet 600
      accent: "#ea580c",     // Orange 600
      background: "#ffffff", // White
      text: "#1f2937"       // Gray 800
    },
    dark: {
      primary: "#34d399",    // Emerald 400
      secondary: "#a78bfa",  // Violet 400
      accent: "#fb923c",     // Orange 400
      background: "#111827", // Gray 900
      text: "#f3f4f6"       // Gray 100
    }
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Privacy", href: "/privacy" },
    { label: "Terms", href: "/terms" },
    { label: "Contact", href: "#contact" }
  ],
  aboutPage: {
    title: "About Data Asset",
    subtitle: "Revolutionizing Data Management",
    mission: {
      title: "Our Mission",
      content: "To help organizations unlock the full potential of their data assets."
    },
    team: [
      {
        name: "Emma Thompson",
        role: "CEO",
        bio: "Pioneer in data management solutions."
      },
      {
        name: "James Lee",
        role: "Head of Analytics",
        bio: "Expert in big data and analytics."
      },
      {
        name: "Anna Kowalski",
        role: "Chief Data Scientist",
        bio: "PhD in Data Science, former Google researcher."
      }
    ],
    values: [
      {
        title: "Innovation",
        description: "Pushing the boundaries of data management."
      },
      {
        title: "Security",
        description: "Ensuring the highest level of data protection."
      },
      {
        title: "Efficiency",
        description: "Optimizing data processes for better results."
      }
    ]
  },
  portfolioPage: {
    title: "Solutions Portfolio",
    subtitle: "Comprehensive Data Management Solutions",
    companies: [
      {
        name: "DataFlow",
        description: "Real-time data processing",
        status: "Enterprise",
        year: 2020
      },
      {
        name: "SecureVault",
        description: "Data security platform",
        status: "Enterprise",
        year: 2019
      },
      {
        name: "AnalyticsPro",
        description: "Advanced analytics suite",
        status: "Enterprise",
        year: 2021
      }
    ],
    investmentCriteria: [
      {
        title: "Data Focus",
        description: "Solutions centered on data management"
      },
      {
        title: "Security",
        description: "Strong security foundations"
      },
      {
        title: "Scalability",
        description: "Enterprise-grade architecture"
      }
    ]
  },
  homePage: {
    hero: {
      title: "Master Your Data Assets",
      highlight: "Unlock True Value",
      description: "Comprehensive solutions for modern data management challenges."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};
