import type { SiteConfig } from "../sites.ts";

export const techVenturesConfig: SiteConfig = {
  company: {
    name: "Tech Ventures",
    tagline: "Investing in Tomorrow's Technology",
    description: "Strategic investment firm focused on emerging technologies"
  },
  theme: {
    primary: '#059669',    // Green
    secondary: '#047857',
    accent: '#10b981',
    background: '#f9fafb',
    text: '#111827',
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Privacy", href: "/privacy" },
    { label: "Terms", href: "/terms" },
    { label: "Contact", href: "#contact" }
  ],
  aboutPage: {
    title: "About Tech Ventures",
    subtitle: "Shaping the Future of Technology Investment",
    mission: {
      title: "Our Mission",
      content: "To identify and support the next generation of technology leaders."
    },
    team: [
      {
        name: "David Wilson",
        role: "Managing Partner",
        bio: "Former VC with multiple successful exits."
      },
      {
        name: "Lisa Chang",
        role: "Investment Director",
        bio: "Specialized in early-stage tech investments."
      },
      {
        name: "Robert Martinez",
        role: "Technical Advisor",
        bio: "20+ years in software development and architecture."
      }
    ],
    values: [
      {
        title: "Vision",
        description: "Identifying transformative technologies early."
      },
      {
        title: "Partnership",
        description: "Working closely with founders to achieve success."
      },
      {
        title: "Impact",
        description: "Creating positive change through technology."
      }
    ]
  },
  portfolioPage: {
    title: "Investment Portfolio",
    subtitle: "Building Tomorrow's Technology Leaders",
    companies: [
      {
        name: "AI Solutions",
        description: "Enterprise AI platform",
        status: "Series A",
        year: 2020
      },
      {
        name: "BlockTech",
        description: "Blockchain infrastructure",
        status: "Seed",
        year: 2021
      },
      {
        name: "CloudSecure",
        description: "Cloud security solutions",
        status: "Series B",
        year: 2019
      }
    ],
    investmentCriteria: [
      {
        title: "Innovation",
        description: "Revolutionary technology solutions"
      },
      {
        title: "Scalability",
        description: "Potential for rapid growth"
      },
      {
        title: "Leadership",
        description: "Strong founding team"
      }
    ]
  },
  homePage: {
    hero: {
      title: "Investing in Innovation",
      highlight: "Building the Future",
      description: "Supporting visionary entrepreneurs in creating transformative technology companies."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};
