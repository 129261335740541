import { metadataConfig } from "./sites/metadata.ts";
import { techVenturesConfig } from "./sites/techventures.ts";
import { dataAssetConfig } from "./sites/dataasset.ts";

export interface SiteConfig {
  company: {
    name: string;
    tagline: string;
    description: string;
  };
  theme: {
    light: {
      primary: string;
      secondary: string;
      accent: string;
      background: string;
      text: string;
    };
    dark: {
      primary: string;
      secondary: string;
      accent: string;
      background: string;
      text: string;
    };
  };
  footerLinks: {
    label: string;
    href: string;
  }[];
  aboutPage: {
    title: string;
    subtitle: string;
    mission: {
      title: string;
      content: string;
    };
    team: {
      name: string;
      role: string;
      bio: string;
    }[];
    values: {
      title: string;
      description: string;
    }[];
  };
  portfolioPage: {
    title: string;
    subtitle: string;
    companies: {
      name: string;
      description: string;
      status: string;
      year: number;
    }[];
    investmentCriteria: {
      title: string;
      description: string;
    }[];
  };
  homePage?: {
    hero?: {
      title?: string;
      highlight?: string;
      description?: string;
    };
    config?: {
      showPromoVideo?: boolean;
      showContactForm?: boolean;
    };
  };
  media?: {
    promoVideo?: string;
    promoVideoPoster?: string;
  };
}

export function getSiteConfig(siteName?: string): SiteConfig {
  // If no siteName provided, try to get it from environment
  if (!siteName && typeof Deno !== 'undefined') {
    siteName = Deno.env.get("CURRENT_SITE");
  }

  if (!siteName) {
    throw new Error("No site name provided or found in environment");
  }

  switch (siteName.toLowerCase()) {
    case 'metadata':
      return metadataConfig;
    case 'techventures':
      return techVenturesConfig;
    case 'dataasset':
      return dataAssetConfig;
    default:
      throw new Error(`Unknown site: ${siteName}`);
  }
}

export function initializeSite(siteName: string) {
  if (!siteName) {
    throw new Error("No site name provided");
  }
  
  console.log('Getting config for site:', siteName);
  const config = getSiteConfig(siteName);
  
  if (!config) {
    throw new Error(`No configuration found for site: ${siteName}`);
  }

  return config;
}
